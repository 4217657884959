<template>
    <v-card class="pa-2">
        <v-snackbar :timeout="4000" v-model="snackbar">
            Veuillez séléctionner la colonne de {{ colSelected }}
        </v-snackbar>

        <v-col class="text-right">
            <!-- <v-btn
                color="primary"
                fab
                small
                icon
                class="ma-2"
                @click="$emit('closeMediasDialog')">
                    <v-icon>mdi-close</v-icon>
                </v-btn> -->
        </v-col>
        <v-col :class="dragging == true ? 'lf hover' : 'lf'">

            <v-alert v-if="!imported" outlined shaped prominent type="info">
                <h3>Format du Fichier</h3>
                <p>Le format du fichier peut-être de format CSV ou Excel(xls, xlsx)</p>
                <v-btn :href="csvfile" target="_blank" rounded class="ma-3" color="info" dark
                    small><v-icon>mdi-download</v-icon> Je télécharge un fichier d'exemple</v-btn>
            </v-alert>

            <v-alert v-if="imported" outlined shaped prominent type="success">

                <h3>Lecture des colonnes</h3>
                <p>Veuillez séléctionner les colonnes adaptées à votre datas</p>

                <v-row class="d-flex justify-start  justify-space-between  mb-6 pa-2">
                    <v-switch v-if="type == 'foret'" v-model="colonneSelected" hide-details inset value="foret"
                        label="Fôret"></v-switch>

                    <v-switch v-model="colonneSelected" hide-details inset label="Qualité" value="qualite"></v-switch>
                    <v-switch v-if="type == 'foret'" v-model="colonneSelected" hide-details inset label="Hauteur"
                        value="hauteur"></v-switch>
                    <v-switch v-if="type == 'foret'" v-model="colonneSelected" hide-details inset label="Diametre"
                        value="diametre"></v-switch>
                    <v-switch v-if="type == 'foret'" v-model="colonneSelected" hide-details inset value="volume"
                        label="Volume"></v-switch>
                    <v-switch v-model="colonneSelected" hide-details inset value="longitude" label="Longitude"></v-switch>

                    <v-switch v-model="colonneSelected" hide-details inset label="Latitude" value="latitude"></v-switch>

                    <v-switch v-model="colonneSelected" hide-details inset label="Commentaire"
                        value="commentaire"></v-switch>

                    <v-switch v-model="colonneSelected" hide-details inset value="parcelle" label="Parcelle"></v-switch>

                    <v-switch v-model="colonneSelected" hide-details inset value="date" label="Date"></v-switch>

                </v-row>
            </v-alert>

            <!-- <v-col  v-if="!imported">
                    <v-chip label outlined light class="green--text pa-1"><span  class="green--text">(*)</span> Colonnes obligatoires</v-chip>
                    <v-chip label outlined class="red--text pa-1"><span class="red--text">(*)</span> Colonnes facultative</v-chip> <br>
                </v-col>

                <v-simple-table  v-if="!imported"  class="pa-1" fixed-header>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="">
                                    <span class="green--text">(*)</span> Parcelle
                                </th>
                                <th class="">
                                    Opérateur <small>(Nom, Pseudo, E-mail...)</small>
                                </th>
                                <th class="">
                                    <span class="green--text">(*)</span> Qualité (<a>Voir liste</a>)
                                </th>   
                                <th class="">
                                    Longitude
                                </th>
                                <th class="">
                                    Latitude
                                </th>
                                <th class="">
                                    Commentaire
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Foret Noire</td>
                                <td>Martin</td>
                                <td>Bonne</td>
                                <td>3.9141896</td>
                                <td>47.3094892</td>
                                <td>La Topographie de terrain</td>
                            </tr>
                            <tr>
                                <td>Foret Ardenne</td>
                                <td>Jean</td>
                                <td>Brogneux</td>
                                <td>3.6141896</td>
                                <td>46.3094892</td>
                                <td>Qualité de vigne</td>
                            </tr>
                            <tr>
                                <td colspan="6">...</td>
                            </tr>
                            </tbody>
                        </template>
                </v-simple-table> -->

        </v-col>

        <v-col v-if="jsondata.length && imported">
            <v-progress-linear v-if="jsondata.length && !imported" color="red darken-2" rounded indeterminate
                value="100"></v-progress-linear>

            <vue-excel-editor no-paging no-num-col no-footer no-mouse-scroll :allow-add-col="false" :new-if-bottom="false"
                :disable-panel-setting="true" :disable-panel-filter="true" :free-select="true" no-header-edit
                @cell-click="update" :auto-fill-width="true" :autoFillWidth="true" :readonly="true" v-model="jsondata">
                <vue-excel-column :sticky="true" readonly-style="{'background-color': '#ccc'}" @cell-click="updateCel"
                    :readonly="column.disabled" v-for="column in colonnes" :key="column.key" :field="column.key"
                    :label="column.title" type="string" />
            </vue-excel-editor>
        </v-col>
        <v-col>
            <vue-dropzone :disablePreviews="true" @vdropzone-drag-enter="dragging = true"
                @vdropzone-drag-leave="dragging = false" @vdropzone-file-added="fileAddedAsync"
                :maxFiles="Number(10000000000)" :multipleUpload="false" :parallelUpload="5" ref="myVueDropzone"
                id="dropzone" :options="dropzoneOptions" :use-custom-slot="true" />
            <div class="dropzone-custom-content">
                <!-- <h3><v-icon>mdi-upload</v-icon> Déposer votre fichier ici</h3> -->
                <!-- <v-btn block rounded x-large color="primary" dark @click="handleFileImport">
                            <v-icon>mdi-upload</v-icon> Télécharger votre document
                        </v-btn> -->
                <input accept="application/csv" ref="uploader" class="d-none" type="file" @change="onFileChanged">
            </div>
        </v-col>
        <!-- <div v-else>
                <v-row class="d-flex justify-center align-center justify-space-between mt-10 ma-4">
                    <v-col >
                        <h2><v-icon>mdi-upload</v-icon> Déposer votre fichier ici</h2>
                    </v-col>
                </v-row>
        </div> -->

        <!-- <div v-else>
            <v-row class="d-flex justify-center align-center justify-space-between mt-10 ma-4">
                <v-col >
                    <h2><v-icon>mdi-upload</v-icon> Déposer votre fichier ici</h2>
                </v-col>
            </v-row>
    </div> -->

    </v-card>
</template>

<script>
import xhr from '../plugins/axios';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Papa from 'papaparse'

export default {
    name: 'Upload',
    components: {
        vueDropzone: vue2Dropzone,
    },
    props: ['selectedInventaire', 'user'],
    data(vm) {
        return {
            dragging: false,
            type: 'forestier',
            visuals: ["gps"],
            columnsFind: ['longitude', 'latitude'],
            zIndex: 0,
            isOverlay: false,
            snackbar: false,
            colonneSelected: [],
            imported: false,
            colSelected: "",
            config: {
                worker: true,
                delimiter: ";" // auto-detect
            },
            jsondata: [
                // { user: 'kl', name: 'Kenny Linus', phone: '1-891-2345685', gender: 'M', age: 29, birth: '1990-09-01' }
            ],
            csvfile: 'https://cartovia.fr/api/csv-example-file',
            selectedFile: null,
            newMsg: "",
            colonnes: [],
            selected: [],
            medias: [],
            dropzoneOptions: {
                url: `${process.env.VUE_APP_BASE_ML}upload`, //'https://cartovia.fr/api/upload',
                acceptedFiles: '.doc, .xls, .docx, .xlsx, .txt',
                disablePreviews: true,
                autoProcessQueue: false,
                maxFilesize: 4,
                acceptedFiles: 'text/csv',
                dictDefaultMessage: "<i class='mdi mdi-upload'></i>Télécharger votre inventaire au format CSV"
            },
        }
    },
    async mounted() {
    },
    async created() {
        let res = await xhr.post('/get-medias', { user: this.user.uid, inv: this.selectedInventaire._id });

        if (res.data) {
            this.medias = res.data
        }
    },
    watch: {
        colonneSelected(val) {
            // if(this.colonneSelected.length){
            //     let lastElt = this.colonneSelected[this.colonneSelected.length - 1];
            //     this.colSelected  = lastElt
            //     this.snackbar = true
            //     this.isOverlay = true
            // }
        }

    },
    methods: {
        onColSelect(colNum) {
            console.info("col select", colNum);
        },
        async update(cel) {
            console.log(cel, "cel");
        },
        async updateCel(col, la) {
            console.log(col, la, "col");
        },
        async removeMedia(media) {
            let res = await xhr.post('/remove-medias', {
                media,
                user: this.user
            })
            this.medias = res.data

        },
        handleFileImport() {
            this.$refs.uploader.click();
        },
        onFileChanged(e) {
            // this.imported = true
            this.selectedFile = e.target.files[0];
            console.log("this.selectedFile", this.selectedFile);
            this.$emit('uploadFile', this.selectedFile)
        },
        slugify(str) {
            return str
                .toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')
                .replace(/[\s_-]+/g, '-')
                .replace(/^-+|-+$/g, '')
        },
        async fileAddedAsync(file) {
            this.selectedFile = file
            this.dragging = false
            // this.imported = true

            console.log("Feuu !!");

            // const levenshteinDistance = (str1 = '', str2 = '') => {
            //     const track = Array(str2.length + 1).fill(null).map(() =>
            //         Array(str1.length + 1).fill(null));
            //     for (let i = 0; i <= str1.length; i += 1) {
            //         track[0][i] = i;
            //     }
            //     for (let j = 0; j <= str2.length; j += 1) {
            //         track[j][0] = j;
            //     }
            //     for (let j = 1; j <= str2.length; j += 1) {
            //         for (let i = 1; i <= str1.length; i += 1) {
            //             const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            //             track[j][i] = Math.min(
            //                 track[j][i - 1] + 1, // deletion
            //                 track[j - 1][i] + 1, // insertion
            //                 track[j - 1][i - 1] + indicator, // substitution
            //             );
            //         }
            //     }
            //     return track[str2.length][str1.length];
            // }

            // let columnsFind = this.columnsFind
            //     let parsing = () => new Promise((resolve, reject) => { 
            //         Papa.parse(this.selectedFile,  {...this.config, complete(results) {

            //             // console.log("Finished:", results.data)

            //         let datas = []
            //                 // console.log("resultssss", results.data[0]);


            //         let nameCols =[]

            //         console.log('resultats', results.data);

            //         // search foret
            //         let foretWords = ["foret", "forêt", "forest", "name", "nom", "surname"]
            //         let foretQualite = ["qualité", "qualite", "quality", "qual"]
            //         let foretDiametre = ["diam", "diameter", "diamètre", "circonference"]
            //         let foretEssence = ["essence", "arbre", "tree", "espèce"]
            //         let foretParcelle = ["parcelle", "parcelles", "nom", "name", "localisation"]
            //         let foretVolume = ["vol", "volume", "litre", "litrage"]
            //         let foretLongitude = ["long", "longitude", "lng"]
            //         let foretLatitude = ["lat", "latitude"]
            //         let foretCommentaire = ["commentaire", "comment", "comm"]
            //         let foretHauteur = ["Hauteur", "height"]

            //         let allColumnAlias = {
            //             foret: foretWords.join(','),
            //             qualite: foretQualite.join(','),
            //             diametre: foretDiametre.join(','),
            //             essence: foretEssence.join(','),
            //             parcelle: foretParcelle.join(','),
            //             volume: foretVolume.join(','),
            //             longitude: foretLongitude.join(','),
            //             latitude: foretLatitude.join(','),
            //             commentaire: foretCommentaire.join(','),
            //             hauteur: foretHauteur.join(','),
            //         }

            //         let columnsSearch = {
            //             diametre: foretDiametre,
            //             foret: foretWords,
            //             qualite: foretQualite,
            //             essence: foretEssence,
            //             parcelle: foretParcelle,
            //             volume: foretVolume,
            //             longitude: foretLongitude,
            //             latitude: foretLatitude,
            //             commentaire: foretCommentaire,
            //             hauteur: foretHauteur
            //         }

            //         //  columns
            //         let cols = [null, "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

            //         // if headers

            //         let headers = results.data[0]

            //         if(headers.length){

            //         // console.log('headers', headers);
            //             for(let item in columnsSearch){

            //                 headers.map((header) => {
            //                     if(header && header.length > 2){
            //                         let tab = columnsSearch[item]
            //                         let reg = new RegExp(header.toLowerCase(), "ig")
            //                         const match = tab.find(value => reg.test(value));


            //                         if(match){
            //                             columnsFind[item] = true
            //                         }

            //                     }
            //                 })
            //             }

            //         }




            //         // or not... : use api trained
            //         if (results.data && results.data.length) {
            //             let rowNum = 1
            //             for(let row of results.data){
            //                 console.log("row", row);
            //                 let colNum = 1
            //                 for(let col of row){
            //                     if(foretWords.includes(col.toLowerCase())){
            //                         console.log("col foret", cols[colNum]);
            //                     }
            //                     colNum++
            //                 }
            //             // if(results.length && results.data[0].length){
            //                 nameCols = results.data[0]
            //             // }
            //                 rowNum++
            //             }
            //         }

            //         let obj = {}

            //         console.log("results.data", results.data);
            //         let slug = (str) => str
            //                     .toLowerCase()
            //                     .trim()
            //                     .replace(/[^\w\s-]/g, '')
            //                     .replace(/[\s_-]+/g, '-')
            //                     .replace(/^-+|-+$/g, '')

            //         for (let row of results.data) {
            //             //   console.log(row, "row");
            //             let it = 0
            //             for (let col of nameCols) {
            //                 let colRe = slug(col)
            //                 if(row[it]){
            //                     obj[colRe] = row[it]
            //                 }
            //                 it++
            //             }
            //             datas.push(obj)
            //         }
            //                 //  { user: 'hc', name: 'Harry Cole', phone: '1-415-2345678', gender: 'M', age: 25, birth: '1997-07-01' },
            //              resolve({datas: datas, columns: nameCols, columnsFind })

            //     },  error(err, file) {
            //         reject(err)
            //     }
            //         })

            //    });

            //    const datas = await parsing()
            //    this.jsondata = datas.datas;
            //    this.columnsFind = datas.columnsFind

            //    console.log(this.columnsFind, "this.columnsFind finisehd");

            // console.log("jsondata", this.jsondata);

            //    console.log("this.jsondata", this.jsondata);

            // let nameCol = element[0]
            // for(let col in element){

            // }
            // console.log("columns", datas);
            // let tabArrays = {};
            // for (let column of  datas.columns) {
            //     tabArrays[column] = ''
            //      let colRe = this.slugify(column)
            //      console.log("columnssss", column);
            //     this.colonnes.push({
            //         title: column,
            //         key: colRe,
            //         disabled: this.columnsFind[column]
            //     })

            // }

            // this.$emit('closeMediasDialog')
            // await this.load(invt.data)
            // this.dialogInventaireLight = false

            // this.snack = "Votre arbres d'inventaire ont été mise à jour"
            // this.snackbar = true


        },

    }

}

</script>
<style lang="scss">
#my-dropzone {
    height: 100vh;
    width: 100vw;
}

#listeSupported {
    li {
        display: inline;
        margin: 10px;
    }
}

.v-input--checkbox.v-input--is-label-active {
    label {
        text-decoration: line-through;
    }
}
</style>